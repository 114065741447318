import React, { useRef, useState, useEffect, useFor } from "react";
import Layout from "../components/layout";
import style from "./landing.module.scss"
import VideoPlayer from "../components/videoPlayer"
import Markdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import Socials from "../components/socials"

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import {
  useStaticQuery,
  graphql
} from "gatsby";

function once(el, event, fn, opts) {
  var onceFn = function (e) {
    el.removeEventListener(event, onceFn);
    fn.apply(this, arguments);
  };
  el.addEventListener(event, onceFn, opts);
  return onceFn;
}

function splitByNewLine(str) {
  return str
    .split("\n")
    .map(it => `<div class="line">${it}</div>`)
    .join("");
}

function splitByWords(str) {
  return str
    .split(" ")
    .map(it => `<div class="word">${it}</div> `)
    .join("");
}

function splitByLetter(str) {
  return str
    .split('')
    .map(it => `<div class="letter">${it}</div> `)
    .join("");
}

function splitText(el) {
  let text = el.textContent;

  text = text.split(" ")
    .map(word => splitByLetter(word))
    .map(it => `<div class="word">${it}</div> `)
    .join("");

  el.innerHTML = text;

  let letters = el.querySelectorAll(".letter")

  return Array.prototype.slice.call(letters);
}

export default () => {
  const layoutRef = useRef(null)
  const introRef = useRef()
  const aboutRef = useRef()
  const contactsRef = useRef()

  const [allVideoLoaded, setAllVideoLoaded] = useState(false);

  const {
    strapiLanding,
    strapiAbout,
    strapiContacts,
  } = useStaticQuery(query);

  useEffect(() => {
    if (!document) {
      return;
    }

    if (!window["fetch"]) {
      return console.error("Fetch not found")
    }

    const allVideofetchPromise = []

    let allVideos = layoutRef.current.querySelectorAll("video");
    allVideos = Array.prototype.slice.call(allVideos);
    allVideos.forEach(videoEl => {
      console.log(videoEl.src);
      videoEl.style.opacity = 0;
      const promise = fetch(videoEl.src)
        .then((response) => response.blob())
        .then((response) => {
          const blobURL = URL.createObjectURL(response);

          videoEl.setAttribute("src", blobURL);
          videoEl.currentTime = 0;
          console.log(blobURL, videoEl);
          videoEl.style.opacity = 1;

          return new Promise((resolve) => {
            once(videoEl, "durationchange", (e) => {
              console.log("videEl.duration", videoEl.duration);
              resolve(videoEl.duration);
            })
          })
        });
      allVideofetchPromise.push(promise)
    })

    once(document.documentElement, "touchstart", function (e) {
      allVideos.forEach(videoEl => {
        videoEl.play();
        videoEl.pause();
      });
    });

    Promise.all(allVideofetchPromise).then(() => {
      console.log("all video loaded");
      setAllVideoLoaded(true);
    })

  }, []);

  useEffect(() => {
    if (!allVideoLoaded) {
      return
    }

    if (!document) {
      return;
    }

    console.log("Init all animations");

    gsap.registerPlugin(ScrollTrigger);

    // INTRO //////////////////////////////////
    const introTextEl = Array.prototype.slice.call(introRef.current.querySelectorAll(".text .line"));
    let introTextElLetters = []
    introTextEl.forEach((textLine) => {
      let letters = splitText(textLine);
      introTextElLetters.push(letters);
    })
    gsap.from(introTextElLetters, {
      ease: "elastic.out(1, 0.3)",
      opacity: 0,
      y: () => ((Math.random() * 30 - 50) * 0.2),
      x: () => ((Math.random() * 30 - 15) * 0.2),
      rotation: () => ((Math.random() * 30 - 15) * 2.6),
      stagger: {
        each: 0.02,
      },
      scrollTrigger: {
        trigger: introTextEl.current,
        start: "0% 0%",
        end: "+500px 0%",
        scrub: 1,
        // markers: true,
        // scrub: true,
      }
    })

    const introVideoEl = introRef.current.querySelector("video");

    introVideoEl.addEventListener('timeupdate', () => {
      if (introVideoEl.paused) {
        return
      }
      if (introVideoEl.currentTime >= strapiLanding.loopEnd) {
        introVideoEl.currentTime = strapiLanding.loopStart
      }
    });


    setTimeout(() => {
      introVideoEl.currentTime = 0;
      introVideoEl.play();
    }, 10);

    const introVideoTl = gsap.timeline({
      defaults: { duration: introVideoEl.duration },
      scrollTrigger: {
        trigger: introRef.current,
        start: "+500px 0%",
        end: "80% 0%",
        // markers: true,
        // scrub: true,
        scrub: 1,
        onEnter: (e) => {
          introVideoEl.pause()
          console.log("enter");
        },
        onLeaveBack: (e) => {
          introVideoEl.play()
          console.log("onLeaveBack", strapiLanding.loopStart);
        },
        onLeave: (e) => {
          console.log("onLeave");
        }
      }
    })
    introVideoTl.fromTo(introVideoEl,
      {
        currentTime: () => {
          return strapiLanding.loopEnd;
        }
      },
      {
        currentTime: introVideoEl.duration
      }
    )
    console.log("introVideoEl", introVideoEl, introVideoEl.duration);


    // ABOUT //////////////////////////////////

    let aboutProfiles = aboutRef.current.querySelectorAll(".content > .profile");

    let aboutVideos = aboutRef.current.querySelectorAll(".content > .profile > .wrapper > .video video");
    aboutVideos.forEach((videoEl, i) => {
      const tl = gsap.timeline({
        defaults: { duration: videoEl.duration },
        scrollTrigger: {
          trigger: aboutProfiles[i],
          start: "top top",
          end: "30% 50%",
          // markers: true,
          // scrub: true,
          scrub: 1,
        }
      })
      tl.fromTo(videoEl,
        {
          currentTime: 0
        },
        {
          currentTime: videoEl.duration || 1
        }
      )
    })

    let aboutTitles = aboutRef.current.querySelectorAll(".content > .profile > .wrapper > .text > .title");
    aboutTitles.forEach((textEl, i) => {
      let letters = splitText(textEl)

      gsap.from(letters, {
        ease: "elastic.out(1, 0.3)",
        opacity: 0,
        y: () => ((Math.random() * 30 - 50) * 0.2),
        x: () => ((Math.random() * 30 - 15) * 0.2),
        rotation: () => ((Math.random() * 30 - 15) * 2.6),
        stagger: {
          each: 0.02,
        },
        scrollTrigger: {
          trigger: aboutProfiles[i],
          start: "top top",
          end: "50% 50%",
          scrub: 1,
          // scrub: true,
        }
      })
    })

    let aboutTexts = aboutRef.current.querySelectorAll(".content > .profile > .wrapper > .text > p");

    aboutTexts.forEach((textEl, i) => {
      let letters = splitText(textEl)
      gsap.from(letters, {
        ease: "elastic.out(1, 0.3)",
        opacity: 0,
        y: () => ((Math.random() * 30 - 50) * 0.2),
        x: () => ((Math.random() * 30 - 15) * 0.2),
        rotation: () => ((Math.random() * 30 - 15) * 2.6),
        stagger: {
          each: 0.01,
        },
        scrollTrigger: {
          trigger: aboutProfiles[i],
          start: "20% top",
          end: "70% 70%",
          // markers: true,
          scrub: 1,
          // scrub: true,
        }
      })
    })

    // Contacts ////////////////////////////////////
    const contactVideoEl = contactsRef.current.querySelector("video");
    const contactsTl = gsap.timeline({
      defaults: { duration: contactVideoEl.duration },
      scrollTrigger: {
        trigger: contactsRef.current,
        start: "0% 0%",
        end: "+1000px 0%",
        // markers: true,
        // scrub: true,
        scrub: 1,
      }
    })
    contactsTl.fromTo(contactVideoEl,
      {
        currentTime: 0
      },
      {
        currentTime: contactVideoEl.duration || 4
      }
    )
    console.log("duration", contactVideoEl.duration);

    const contactEmailEl = contactsRef.current.querySelector(".mail > a");
    let contactEmailLetters = splitText(contactEmailEl);
    gsap.from(contactEmailLetters, {
      ease: "elastic.out(1, 0.3)",
      opacity: 0,
      y: () => ((Math.random() * 30 - 50) * 0.2),
      x: () => ((Math.random() * 30 - 15) * 0.2),
      rotation: () => ((Math.random() * 30 - 15) * 2.6),
      stagger: {
        each: 0.02,
      },
      scrollTrigger: {
        trigger: contactsRef.current,
        start: "0% 0%",
        end: "+1000px 0%",
        scrub: 1,
        // markers: true,
        // scrub: true,
      }
    })

    const contactBorder = contactsRef.current.querySelector(".mail > .border");
    gsap.from(contactBorder, {
      ease: "ease-in-out",
      opacity: 0,
      scaleX: 0,
      stagger: {
        each: 0.01,
      },
      scrollTrigger: {
        trigger: contactsRef.current,
        start: "0% 0%",
        end: "+1000px 0%",
        scrub: 1,
        // markers: true,
        // scrub: true,
      }
    })

    const contactSocilasEls = contactsRef.current.querySelectorAll(".socials > a");
    gsap.from(contactSocilasEls, {
      ease: "elastic.out(1, 0.3)",
      opacity: 0,
      y: () => ((Math.random() * 30 - 50) * 0.2),
      x: () => ((Math.random() * 30 - 15) * 0.2),
      scale: 1.3,
      // rotation: () => ((Math.random() * 30 - 15) * 2.6),
      stagger: {
        each: 0.01,
      },
      scrollTrigger: {
        trigger: contactsRef.current,
        start: "0% 0%",
        end: "+1000px 0%",
        scrub: 1,
        // markers: true,
        // scrub: true,
      }
    })
  }, [allVideoLoaded])

  return (
    <Layout
      forwardedRef={layoutRef}
      seo={{ metaTitle: 'Landing' }}
      contentStyle={style.root}
    >
      <section ref={introRef} className="intro" id="intro">
        <div className="background">
          <VideoPlayer
            className="video"
            url={strapiLanding.introVideo.publicURL}
          />
        </div>
        <div className="content">
          <div className="text">
            <Markdown
              skipHtml={false}
              rehypePlugins={[rehypeRaw]}
              children={splitByNewLine(strapiAbout.body)}
            />
          </div>
        </div>
      </section>
      <section ref={aboutRef} className="about" id="about">
        <div className="content">
          {strapiAbout.profiles.map((it) => {
            return (
              <div className="profile" key={it.name}>
                <div className="wrapper">
                  <VideoPlayer
                    className="video"
                    url={it.video.publicURL}
                  />
                  <div className="text">
                    <div className="title">
                      {it.name}
                    </div>
                    <Markdown
                      skipHtml={false}
                      rehypePlugins={[rehypeRaw]}
                      children={it.body} />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </section>
      <section ref={contactsRef} className="contacts" id="contacts">
        <div className="content">
          <VideoPlayer
            className="video"
            url={strapiContacts.video.publicURL}
          />
          {/* <div className="info">
            <h2>Contacts</h2>
            {strapiContacts.body}
          </div> */}
          <div className="mail">
            <a href={`mailto:${strapiContacts.email}`} target="_blank" rel="noreferrer">{strapiContacts.email}</a>
            <div className="border" />
          </div>
          <Socials className="socials" />
        </div>
      </section>
    </Layout >

  )
};

const query = graphql`
query Landing {
  strapiLanding {
    loopStart
    loopEnd
    introVideo {
      publicURL
    }
  }
  strapiAbout {
    body
    profiles {
      body
      name
      video {
        publicURL
      }
    }
  }
  strapiContacts {
    email
    body
    video {
      publicURL
    }
  }
}
`