import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import defaultStyle from "./videoPlayer.module.styl"
// import useAnimationFrame from "use-animation-frame"

export default ({ url, style, onDuration, seeking = 0, className = null }) => {

  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const videoRef = useRef(null)

  const loopStart = 6.5;
  const loopEnd = 16;

  // useLayoutEffect(() => {
  //   const videoEl = videoRef.current;

  //   videoEl.addEventListener('timeupdate', () => {
  //     if (videoEl.paused) {
  //       return
  //     }
  //     if (videoEl.currentTime >= loopEnd) {
  //       videoEl.currentTime = loopStart
  //     }
  //   });

  //   videoEl.addEventListener('durationchange', () => {
  //     setDuration(videoEl.duration)
  //   })
  // }, [])

  // useAnimationFrame((e) => {
  //   if (duration == 0) {
  //     return
  //   }
  //   if (seeking == 0) {
  //     return
  //   }

  //   const videoEl = videoRef.current;
  //   if (!videoEl) {
  //     return;
  //   }

  //   videoEl.pause();

  //   let time;
  //   time = seeking / 1000
  //   time = Math.min(time, duration);
  //   setCurrentTime(time);

  //   if (time >= duration) {
  //     return
  //   }

  //   videoEl.currentTime = time

  // }, [seeking, duration])

  return (
    <div className={(style ? style : defaultStyle.root) + (className ? " " + className : "")}>
      <div className="wrapper">
        <video src={url} type="video/mp4" ref={videoRef} muted playsInline preload="auto" />
      </div>
    </div>
  );
}


